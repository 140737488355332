import { createGlobalStyle, css } from 'styled-components';
import MontserratRegular from '../../../static/montserrat-v18-latin-regular.woff2';
import MontserratMedium from '../../../static/montserrat-v18-latin-500.woff2';
import MontserrratBold from '../../../static/montserrat-v18-latin-700.woff2';

// Coomon styles

const nullify = css`
  margin: 0;
  padding: 0;
`;

// Global

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  src: url(${MontserratRegular}) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  src: url(${MontserratMedium}) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  src: url(${MontserrratBold}) format('woff2');
  font-display: swap;
}

:root {
  --layout-desktop-container: 1280px;
  --wave-offset-padding: 120px;
  --wave-offset-negative-margin: -100px;
  --blue-1: #011027;
  --blue-1-alpha: rgba(1, 16, 39, 0.6);
  --blue-2: #11284a;
  --gold-1: #675c3c;
  --gold-2: #b5a371;
  --gold-2-alpha: rgba(181, 163, 113, 0.90);
  --gold-3: #f4efe2;
  --background-white: #fff;
  --foreground-white: #fff;
  --padding: 20px;
  --gap-2: 60px;
  --gap-3: 40px;
  --gap-4: 30px;
  --gap-5: 20px;
  --gap-6: 10px;
  --gap-7: 5px;
  --font-size-micro: 0.825rem;
  --font-size-micro-1: 0.925rem;
  --font-size: 1rem;
  --font-size-2: 1.125rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.375rem;
  --font-size-5: 1.625rem;
  --font-size-6: 2rem;
  --font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

html {
  font-size: 100%;
  ${nullify}
  line-height: 1.5;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family);
  font-weight: 500;
  ${nullify}
}

h1,
h2,
h3,
p,
ul,
li {
  ${nullify}
}

button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

b,
strong {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.menuActive::before {
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    background: var(--gold-2);
    position: absolute;
    bottom: -4px;
    left: 0;
}

.menuActiveMobile {
    color: var(--gold-2) !important;
}

.simplebar-scrollbar::before {
  background-color: var(--blue-2);
}

@media screen and (min-width: 811px) {
  #viewport_slider .simplebar-scrollbar::before {
    background-color: transparent;
  }
}

.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right], .iubenda-tp-btn[data-tp-float][data-tp-float=top-right] {
  display: none !important;
}

.markerHandler {
  & svg {
    cursor: pointer;
    width: 45px;
    height: 45px;
  }
}

.simplebarAutoComplete {
  max-height: 560px;
  background: white;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    height: 100vh;
    background: white none repeat scroll 0% 0%;
    border-radius: 0;
    max-height: unset;
  }
}

.parallaxScroll {
  width: 100%;
  margin-top: -40px;

  @media screen and (min-width: 1920px) {
    margin-top: -60px;
  }
}

.parallaxHeroFoodCollection {
  width: 100%;
}

.foodCollectionClipPath {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 850px;
  min-height: 400px;
  pointer-events: none;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    max-height: 1100px;
  } 

  @media screen and (max-width: 600px) {
    display: none;
  } 
}

.foodCollectionClipPathShape {
  @media screen and (max-width: 600px) {
    display: none;
  } 
}

.hideSvgWaveProductHero {
  @media screen and (max-width: 480px) {
    display: none;
  }
}

.compassDrawing {
  width: 400vw;
  position: absolute;
  left: 0;
  top: 130px;

  @media screen and (max-width: 960px) {
    top: 130px;
  }

  @media screen and (max-width: 1200px) {
    top: 0;
  }

  @media screen and (max-width: 1700px) {
    top: 30px;
  }

  @media screen and (min-width: 1701px) {
    top: -80px;
  }

  @media screen and (min-width: 2000px) {
    top: -150px;
  }
}

.clipPathController {
  @media screen and (max-width: 810px) {
    display: none;
  }
}

.mailIcon {
  @media screen and (max-width: 1120px) {
    display: none !important;
  }
}

`;
